var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),_c('ASuccessWithoutReload',{attrs:{"api":this.api},on:{"close":_vm.closeSuccessModal}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(!_vm.api.isLoading)?_c('v-data-table',{attrs:{"items":_vm.data,"search":_vm.search,"headers":_vm.dataHeader},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.data.indexOf(item)+1)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-h4 pt-4",attrs:{"flat":"","height":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-toolbar-title',{staticClass:"font-weight-bold text-h4 my-3"},[_vm._v(" List of past webinars ")])],1),_c('v-row',[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)]},proxy:true},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [(item.start_time!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.start_time))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [(item.end_time!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.end_time))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [(item.user_name!=' ')?_c('span',[_vm._v(" "+_vm._s(item.user_name)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.attendance",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","to":{name:'PageWebinarParticipants',params:{id:item.meeting_id}}}},[_vm._v(" Participants ")])]}},{key:"item.zoom_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.zoom_url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.zoom_url)+" ")])]}}],null,false,1430574213)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
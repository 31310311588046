<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-if="!api.isLoading"
            :items="data"
            :search="search"
            :headers="dataHeader">
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    List of past webinars
                                </v-toolbar-title>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense
                                    outlined 
                                    label="Search">

                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
            <template
                v-slot:item.start_time="{item}">
                <span
                    v-if="item.start_time!=null">
                    {{ convertTimeZone(item.start_time) }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.end_time="{item}">
                <span
                    v-if="item.end_time!=null">
                    {{ convertTimeZone(item.end_time) }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.user_name="{item}">
                <span
                    v-if="item.user_name!=' '">
                    {{ item.user_name }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.attendance="{item}">
                <v-btn
                    color="primary" 
                    :to="{name:'PageWebinarParticipants',params:{id:item.meeting_id}}">
                    Participants
                </v-btn>
            </template>
            <template
                v-slot:item.zoom_url="{item}">
                <a
                    :href="item.zoom_url"
                    target="_blank">
                    {{ item.zoom_url }}
                </a>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
export default {
    components:{
    ASuccessWithoutReload
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Title',
                value:'meeting_title'
            },
            {
                text:'Created by',
                value:'user_name'
            },
            {
                text:'Start date',
                value:'start_time',
            },
            {
                text:'End date',
                value:'end_time'
            },
            {
                text:'Zoom url',
                value:'zoom_url'
            },
            {
                text:'Attendance',
                value:'attendance'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getListOfWebinars") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchListOfCourseApi = this.fetchListOfCourse();
            this.$api.fetch(fetchListOfCourseApi);

        },
        isJSonString(str){
            try{    
                JSON.parse(str);
                return true;
            }catch(e){
                return false;
            }
        },
        fetchListOfCourse() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/meetings/webinars";
            return tempApi;
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LLL");
        },
    }
}
</script>